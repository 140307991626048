import cookie from "cookie";
import type { IncomingMessage } from "http";

export function getCookie(cookieName) {
  if (typeof window === "undefined") {
    return "";
  }
  return window.document.cookie.split(";").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    if (name.trim() == cookieName) return value;
    else return acc;
  }, undefined);
}

export function removeCookie(cookieName) {
  document.cookie = [
    `${cookieName}=''`,
    "expires=Thu, 01 Jan 1970 00:00:00 GMT",
    "path=/",
    "samesite=strict",
  ].join(";");
}

export function parseCookies(req?: IncomingMessage) {
  if (!req || !req.headers) {
    return {};
  }
  return cookie.parse(req.headers.cookie || "");
}
